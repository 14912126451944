




































































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Types
import { UsersActions } from '@store/users/types'

// Interfaces
import { Role } from '@/store/common/Interface'
import { User, UsersState } from '@store/users/interfaces'

// Modules
const NSUsers = namespace('usersModule')

@Component({
  name: 'UserCard',
})
export default class UserCardComponent extends Vue {
  @Prop({ default: () => ({}) }) private user!: User

  @Prop(Function)
  private dialogChangePassword!: (user: User) => void

  @Prop(Function)
  private handleDisconnectProjectToUser!: (userId: number) => Promise<void>

  @NSUsers.State((state: UsersState) => state.roles)
  private roles!: Role[]

  @NSUsers.Action(UsersActions.A_TOGGLE_USER_ACTIVE)
  private toggleUserActive!: (user) => Promise<void>

  private get role(): string {
    const [role] = this.roles.filter(r => r.id === this.user.roleId)

    return role.name
  }

  private handleToggleUserActive(user: User) {
    this.toggleUserActive(user)
      .then(() => {
        this.$notify({
          title: 'Выполнено',
          message: 'Статус пользователя изменен',
          type: 'success',
        })
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }
}
